// @flow
import React from 'react';
import Dropzone from 'react-dropzone';

declare var notify: any;

type Props = {
  inputId: String,
  inputName: String,
  uploadedFiles: Array<Object>,
};

type State = {
  uploadFiles: Array<Object>,
};

export default class FileUploadView extends React.Component<Props, State> {
  inputFileRef: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      uploadFiles: [],
    };

    this.inputFileRef = React.createRef();
  }

  async componentDidMount() {
    const uploadFiles = await Promise.all(
      this.props.uploadedFiles.map(async file => {
        const fileObject = await this.convertUrlToFile(file);
        return fileObject;
      }),
    );

    const dt = new DataTransfer();
    uploadFiles.forEach(file => dt.items.add(file));

    this.inputFileRef.current.files = dt.files;
    this.setState({
      uploadFiles: uploadFiles,
    });
    $(this.inputFileRef.current).trigger('change');
  }

  convertUrlToFile(file: Object): any {
    return new Promise((resolve, reject) => {
      fetch(file.url)
        .then(response => response.blob())
        .then(blob => new File([blob], file.file_name))
        .then((file: any) => {
          file.preview = URL.createObjectURL(file);
          resolve(file);
        })
        .catch(e => {
          console.log(e);
          reject(e);
        });
    });
  }

  onUploadFile(files: any) {
    if (this.state.uploadFiles.length + files.length > 5) {
      return notify(
        '',
        '添付ファイルは最大5つまでです。',
        'top',
        'center',
        '',
        'danger',
        '',
        '',
      );
    }

    const uploadFiles = [...this.state.uploadFiles, ...files];

    const dt = new DataTransfer();
    uploadFiles.forEach(file => dt.items.add(file));

    this.inputFileRef.current.files = dt.files;
    this.setState({
      uploadFiles: uploadFiles,
    });
    $(this.inputFileRef.current).trigger('change');
  }

  onClickRemove(listIndex: number) {
    const removedFiles = this.state.uploadFiles.filter((file, index) => {
      return index !== listIndex;
    });
    const dt = new DataTransfer();
    removedFiles.forEach(file => dt.items.add(file));

    this.inputFileRef.current.files = dt.files;
    this.setState({ uploadFiles: removedFiles });
    $(this.inputFileRef.current).trigger('change');
  }

  render() {
    const { uploadFiles } = this.state;
    const { inputId, inputName } = this.props;

    return (
      <div className="file-upload-area">
        <Dropzone
          onDrop={files => this.onUploadFile(files)}
          accept=".pdf,.jpg,.jpeg,.png,.gif"
          className="item drop-zone"
        >
          <div className="drop-zone__content">
            <input
              hidden
              ref={this.inputFileRef}
              type="file"
              id={inputId}
              name={inputName}
              accept=".pdf,.jpg,.jpeg,.png,.gif"
              multiple={true}
            />
            <i className="fas fa-cloud-upload-alt fa-3x" aria-hidden="true" />
            <p className="mb-0 attention-main">
              ここにファイルをドロップしてください。
            </p>
            <p className="mb-40 attention-main">またはファイルを選択する</p>
            <p className="mb-0 attention-sub text-center">
              pdf、jpg、jpeg、png、gifのみ
              <br className="tb" />
              アップロード可能です。
            </p>
            <p className="mb-0 attention-sub text-center">
              スマートフォンで撮影した写真も
              <br className="tb" />
              アップロード可能です。
            </p>
          </div>
        </Dropzone>

        {uploadFiles.length > 0 && (
          <div className="item uploaded-files">
            <p className="uploaded-files-title">アップロード済ファイル</p>
            <ul className="uploaded-files-list">
              {uploadFiles.map((file, index) => {
                return (
                  <li key={index}>
                    <div className="d-flex align-items-center justify-content-between">
                      <div className="d-flex align-items-center overflow-hidden">
                        <img src={file.preview} />
                        <p className="mb-0 filename">{file.name}</p>
                      </div>
                      <div>
                        <button
                          type="button"
                          className="remove"
                          onClick={() => this.onClickRemove(index)}
                        >
                          &times;
                        </button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
    );
  }
}
